import { useEffect, useState } from "react";
import { Moon, Sun } from "../../assets/images/svgfiles/Svg";

const initializeTheme = () => {
  const storedTheme = localStorage.getItem('theme');
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

  if (storedTheme) {
    document.documentElement.setAttribute('data-theme', storedTheme);
  } else {
    // Set default 
    const defaultTheme = prefersDarkScheme ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', defaultTheme);
    localStorage.setItem('theme', defaultTheme);
  }
};

initializeTheme();

export default function ThemeToggle() {
  const [theme, setTheme] = useState(null);
  
  useEffect(() => {
   // Get theme from localStorage
    const storedTheme = localStorage.getItem('theme');
    setTheme(storedTheme || 'dark'); 
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  return (
    <>
      <button onClick={toggleTheme} className="light-dark-themetoogle px-2 py-2 sm-moblie:p-1 bg-primary-light text-dark-gray border rounded-md">
        {theme === 'light' ? (
          <Sun height="20" width="20" className="sm-moblie:h-4 sm-moblie:w-4"/>
        ) : (
          <Moon height="20" width="20" className="sm-moblie:h-4 sm-moblie:w-4"/>
        )} 
      </button>
    </>
  );
}
