import React from 'react'

const SearchIcon = ({ size = 14, ...otherProps }) => {
    return (
        <div className='search_dark_icon'>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M20.0384 20.2L25 25M12.1429 6.14286C14.9832 6.14286 17.2857 8.44539 17.2857 11.2857M23.4 12.2C23.4 18.3856 18.3856 23.4 12.2 23.4C6.01441 23.4 1 18.3856 1 12.2C1 6.01441 6.01441 1 12.2 1C18.3856 1 23.4 6.01441 23.4 12.2Z" stroke="#4C4C4C" strokeWidth="1.4" strokeLinecap="round" />
            </svg>
        </div>
    );
};


const EmailIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.2905 0.542914L18.1405 5.35291C19.2055 6.10138 19.8396 7.32124 19.8405 8.62291V15.7529C19.8378 16.8854 19.3854 17.9704 18.5828 18.7693C17.7801 19.5682 16.693 20.0156 15.5605 20.0129H4.28049C3.14801 20.0156 2.06087 19.5682 1.25821 18.7693C0.455554 17.9704 0.00313734 16.8854 0.000488281 15.7529V8.62291C0.001339 7.32124 0.635516 6.10138 1.70049 5.35291L8.55049 0.542914C9.32039 -0.180971 10.5206 -0.180971 11.2905 0.542914ZM17.5221 17.7087C18.0435 17.1911 18.3378 16.4876 18.3405 15.7529V8.62291C18.3265 7.82531 17.9327 7.08228 17.2805 6.62291L10.4305 1.81291L10.3205 1.73291C10.2152 1.62541 10.071 1.56483 9.92049 1.56483C9.76999 1.56483 9.62582 1.62541 9.52049 1.73291L9.41049 1.81291L2.56049 6.62291C1.9083 7.08228 1.5145 7.82531 1.50049 8.62291V15.7529C1.50314 16.4876 1.79752 17.1911 2.31887 17.7087C2.84022 18.2263 3.54584 18.5156 4.28049 18.5129H15.5605C16.2951 18.5156 17.0008 18.2263 17.5221 17.7087Z" fill="#D2D6D9" />
                <path d="M15.9905 9.01291L10.4505 12.8829C10.2865 13.047 10.0613 13.1349 9.82952 13.1254C9.59774 13.1159 9.38052 13.0098 9.23048 12.8329L3.85048 9.01291C3.63438 8.82601 3.33084 8.77813 3.06769 8.88944C2.80454 9.00074 2.6275 9.2519 2.6111 9.53715C2.59471 9.82241 2.74182 10.0922 2.99048 10.2329L8.29048 14.0129C8.72507 14.4175 9.29671 14.6426 9.89048 14.6429C10.4489 14.6288 10.9813 14.4037 11.3805 14.0129L16.8505 10.1929C17.1649 9.96016 17.2477 9.52478 17.0405 9.19291C16.7981 8.8566 16.331 8.77652 15.9905 9.01291Z" fill="#D2D6D9" />
            </svg>
        </div>
    );
};


const PasswordIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="21" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <g clipPath="url(#clip0_445_12153)">
                    <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 9.99997V7.99997C17.25 4.84997 16.36 2.74997 12 2.74997C7.64 2.74997 6.75 4.84997 6.75 7.99997V9.99997C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 9.99997V7.99997C5.25 5.09997 5.95 1.24997 12 1.24997C18.05 1.24997 18.75 5.09997 18.75 7.99997V9.99997C18.75 10.41 18.41 10.75 18 10.75Z" fill="#D2D6D9" />
                    <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z" fill="#D2D6D9" />
                    <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.24997 7 9.24997H17C21.41 9.24997 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z" fill="#D2D6D9" />
                </g>
                <defs>
                    <clipPath id="clip0_445_12153">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

const UserIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="20" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z" fill="#D2D6D9" />
                <path d="M20.5901 22.75C20.1801 22.75 19.8401 22.41 19.8401 22C19.8401 18.55 16.3202 15.75 12.0002 15.75C7.68015 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85015 14.25 12.0002 14.25C17.1502 14.25 21.3401 17.73 21.3401 22C21.3401 22.41 21.0001 22.75 20.5901 22.75Z" fill="#D2D6D9" />
            </svg>
        </div>
    );
};

const DownloadsIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="20" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M20.9 13.2C20.6083 13.2 20.3285 13.3159 20.1222 13.5222C19.9159 13.7285 19.8 14.0083 19.8 14.3V18.7C19.8 18.9917 19.6841 19.2715 19.4778 19.4778C19.2715 19.6841 18.9917 19.8 18.7 19.8H3.3C3.00826 19.8 2.72847 19.6841 2.52218 19.4778C2.31589 19.2715 2.2 18.9917 2.2 18.7V14.3C2.2 14.0083 2.08411 13.7285 1.87782 13.5222C1.67153 13.3159 1.39174 13.2 1.1 13.2C0.808262 13.2 0.528472 13.3159 0.322183 13.5222C0.115892 13.7285 0 14.0083 0 14.3V18.7C0 19.5752 0.347678 20.4146 0.966548 21.0335C1.58542 21.6523 2.42479 22 3.3 22H18.7C19.5752 22 20.4146 21.6523 21.0335 21.0335C21.6523 20.4146 22 19.5752 22 18.7V14.3C22 14.0083 21.8841 13.7285 21.6778 13.5222C21.4715 13.3159 21.1917 13.2 20.9 13.2ZM10.219 15.081C10.3236 15.1811 10.447 15.2596 10.582 15.312C10.7137 15.3702 10.856 15.4003 11 15.4003C11.144 15.4003 11.2863 15.3702 11.418 15.312C11.553 15.2596 11.6764 15.1811 11.781 15.081L16.181 10.681C16.3881 10.4739 16.5045 10.1929 16.5045 9.9C16.5045 9.60707 16.3881 9.32613 16.181 9.119C15.9739 8.91187 15.6929 8.7955 15.4 8.7955C15.1071 8.7955 14.8261 8.91187 14.619 9.119L12.1 11.649V1.1C12.1 0.808262 11.9841 0.528472 11.7778 0.322183C11.5715 0.115892 11.2917 0 11 0C10.7083 0 10.4285 0.115892 10.2222 0.322183C10.0159 0.528472 9.9 0.808262 9.9 1.1V11.649L7.381 9.119C7.27844 9.01644 7.15668 8.93508 7.02268 8.87957C6.88867 8.82407 6.74505 8.7955 6.6 8.7955C6.45496 8.7955 6.31133 8.82407 6.17733 8.87957C6.04332 8.93508 5.92156 9.01644 5.819 9.119C5.71644 9.22156 5.63508 9.34332 5.57957 9.47733C5.52407 9.61133 5.4955 9.75496 5.4955 9.9C5.4955 10.045 5.52407 10.1887 5.57957 10.3227C5.63508 10.4567 5.71644 10.5784 5.819 10.681L10.219 15.081Z" fill="#ECECEC" />
            </svg>

        </div>
    );
};

const BackIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="20" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M0.157667 5.61738L5.58527 0.18973C5.78024 -0.0379381 6.12289 -0.0644783 6.35056 0.130539C6.57822 0.32551 6.60476 0.668155 6.40975 0.895823C6.39156 0.917046 6.37178 0.936869 6.35056 0.955014L1.85107 5.45996H23.3391C23.6388 5.45996 23.8818 5.70297 23.8818 6.00275C23.8818 6.30253 23.6388 6.5455 23.3391 6.5455H1.85107L6.35056 11.045C6.57822 11.24 6.60476 11.5826 6.40975 11.8103C6.21473 12.0379 5.87213 12.0645 5.64446 11.8695C5.62324 11.8513 5.60342 11.8315 5.58527 11.8103L0.157619 6.38262C-0.0527897 6.171 -0.0527897 5.8291 0.157667 5.61738Z" fill="#D2D6D9" />
            </svg>
        </div>
    );
};
const EyeIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>         
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M11.9999 16.33C9.60992 16.33 7.66992 14.39 7.66992 12C7.66992 9.61004 9.60992 7.67004 11.9999 7.67004C14.3899 7.67004 16.3299 9.61004 16.3299 12C16.3299 14.39 14.3899 16.33 11.9999 16.33ZM11.9999 9.17004C10.4399 9.17004 9.16992 10.44 9.16992 12C9.16992 13.56 10.4399 14.83 11.9999 14.83C13.5599 14.83 14.8299 13.56 14.8299 12C14.8299 10.44 13.5599 9.17004 11.9999 9.17004Z" fill="#4C4C4C" />
                <path d="M12.0001 21.02C8.24008 21.02 4.69008 18.82 2.25008 15C1.19008 13.35 1.19008 10.66 2.25008 8.99998C4.70008 5.17998 8.25008 2.97998 12.0001 2.97998C15.7501 2.97998 19.3001 5.17998 21.7401 8.99998C22.8001 10.65 22.8001 13.34 21.7401 15C19.3001 18.82 15.7501 21.02 12.0001 21.02ZM12.0001 4.47998C8.77008 4.47998 5.68008 6.41998 3.52008 9.80998C2.77008 10.98 2.77008 13.02 3.52008 14.19C5.68008 17.58 8.77008 19.52 12.0001 19.52C15.2301 19.52 18.3201 17.58 20.4801 14.19C21.2301 13.02 21.2301 10.98 20.4801 9.80998C18.3201 6.41998 15.2301 4.47998 12.0001 4.47998Z" fill="#4C4C4C" />
            </svg>

        </div>
    );
};
const EyeSlashIcon = ({ ...otherProps }) => {
    return (
        <div className='auth_dark_icon'>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M9.46992 15.28C9.27992 15.28 9.08992 15.21 8.93992 15.06C8.11992 14.24 7.66992 13.15 7.66992 12C7.66992 9.61004 9.60992 7.67004 11.9999 7.67004C13.1499 7.67004 14.2399 8.12004 15.0599 8.94004C15.1999 9.08004 15.2799 9.27004 15.2799 9.47004C15.2799 9.67004 15.1999 9.86004 15.0599 10L9.99992 15.06C9.84992 15.21 9.65992 15.28 9.46992 15.28ZM11.9999 9.17004C10.4399 9.17004 9.16992 10.44 9.16992 12C9.16992 12.5 9.29992 12.98 9.53992 13.4L13.3999 9.54004C12.9799 9.30004 12.4999 9.17004 11.9999 9.17004Z" fill="#4C4C4C" />
                <path d="M5.59984 18.51C5.42984 18.51 5.24984 18.45 5.10984 18.33C4.03984 17.42 3.07984 16.3 2.25984 15C1.19984 13.35 1.19984 10.66 2.25984 8.99998C4.69984 5.17998 8.24984 2.97998 11.9998 2.97998C14.1998 2.97998 16.3698 3.73998 18.2698 5.16998C18.5998 5.41998 18.6698 5.88998 18.4198 6.21998C18.1698 6.54998 17.6998 6.61998 17.3698 6.36998C15.7298 5.12998 13.8698 4.47998 11.9998 4.47998C8.76984 4.47998 5.67984 6.41998 3.51984 9.80998C2.76984 10.98 2.76984 13.02 3.51984 14.19C4.26984 15.36 5.12984 16.37 6.07984 17.19C6.38984 17.46 6.42984 17.93 6.15984 18.25C6.01984 18.42 5.80984 18.51 5.59984 18.51Z" fill="#4C4C4C" />
                <path d="M11.9996 21.02C10.6696 21.02 9.36957 20.75 8.11957 20.22C7.73957 20.06 7.55957 19.62 7.71957 19.24C7.87957 18.86 8.31957 18.68 8.69957 18.84C9.75957 19.29 10.8696 19.52 11.9896 19.52C15.2196 19.52 18.3096 17.58 20.4696 14.19C21.2196 13.02 21.2196 10.98 20.4696 9.81C20.1596 9.32 19.8196 8.85 19.4596 8.41C19.1996 8.09 19.2496 7.62 19.5696 7.35C19.8896 7.09 20.3596 7.13 20.6296 7.46C21.0196 7.94 21.3996 8.46 21.7396 9C22.7996 10.65 22.7996 13.34 21.7396 15C19.2996 18.82 15.7496 21.02 11.9996 21.02Z" fill="#4C4C4C" />
                <path d="M12.6896 16.27C12.3396 16.27 12.0196 16.02 11.9496 15.66C11.8696 15.25 12.1396 14.86 12.5496 14.79C13.6496 14.59 14.5696 13.67 14.7696 12.57C14.8496 12.16 15.2396 11.9 15.6496 11.97C16.0596 12.05 16.3296 12.44 16.2496 12.85C15.9296 14.58 14.5496 15.95 12.8296 16.27C12.7796 16.26 12.7396 16.27 12.6896 16.27Z" fill="#4C4C4C" />
                <path d="M2.00043 22.75C1.81043 22.75 1.62043 22.68 1.47043 22.53C1.18043 22.24 1.18043 21.76 1.47043 21.47L8.94043 14C9.23043 13.71 9.71043 13.71 10.0004 14C10.2904 14.29 10.2904 14.77 10.0004 15.06L2.53043 22.53C2.38043 22.68 2.19043 22.75 2.00043 22.75Z" fill="#4C4C4C" />
                <path d="M14.5297 10.2199C14.3397 10.2199 14.1497 10.1499 13.9997 9.99994C13.7097 9.70994 13.7097 9.22994 13.9997 8.93994L21.4697 1.46994C21.7597 1.17994 22.2397 1.17994 22.5297 1.46994C22.8197 1.75994 22.8197 2.23994 22.5297 2.52994L15.0597 9.99994C14.9097 10.1499 14.7197 10.2199 14.5297 10.2199Z" fill="#4C4C4C" />
            </svg>

        </div>
    );
};

const RupeesIcon = ({ ...otherProps }) => {
    return (
        <div className='black_white_icon'>
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M7.86884 16.0775C7.57235 16.0775 7.37469 15.9789 7.17703 15.7817L1.24727 9.86619C0.950782 9.57042 0.851952 9.17605 1.04961 8.78168C1.14844 8.38732 1.54376 8.19013 1.93908 8.19013H4.90396C5.69459 8.19013 6.48523 7.89436 6.97937 7.30281C7.57235 6.80985 7.86884 6.02112 7.86884 5.23238C7.86884 4.44365 7.57235 3.65491 6.97937 3.16196C6.48523 2.57041 5.69459 2.27463 4.90396 2.27463H1.93908C1.3461 2.27463 0.950782 1.88026 0.950782 1.28871C0.950782 0.697162 1.3461 0.302795 1.93908 0.302795H12.8103C13.4033 0.302795 13.7986 0.697162 13.7986 1.28871C13.7986 1.88026 13.4033 2.27463 12.8103 2.27463H8.85713C9.45011 3.16196 9.84543 4.14787 9.84543 5.23238C9.84543 6.51407 9.35128 7.79577 8.36298 8.68309C7.47352 9.66901 6.18874 10.162 4.90396 10.162H4.31098L8.56064 14.4014C8.95596 14.7958 8.95596 15.3873 8.56064 15.7817C8.36298 15.9789 8.16533 16.0775 7.86884 16.0775Z" fill="white" />
                <path d="M12.8112 6.21829H1.93995C1.34698 6.21829 0.95166 5.82393 0.95166 5.23238C0.95166 4.64083 1.34698 4.24646 1.93995 4.24646H12.8112C13.4042 4.24646 13.7995 4.64083 13.7995 5.23238C13.7995 5.82393 13.4042 6.21829 12.8112 6.21829Z" fill="white" />
            </svg>
        </div>
    );
};

const EditIcon = ({ ...otherProps }) => {
    return (
        <div className='black_white_icon'>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path d="M11.0717 1.41702L11.7592 0.718116C12.5717 -0.107861 13.9859 -0.107861 14.7984 0.718116L15.3531 1.282C15.5532 1.48372 15.7121 1.72373 15.8205 1.98818C15.9289 2.25263 15.9847 2.53628 15.9847 2.82277C15.9847 3.10926 15.9289 3.39291 15.8205 3.65736C15.7121 3.92181 15.5532 4.16182 15.3531 4.36353L14.6656 5.06244L11.0717 1.40908V1.41702ZM10.2435 2.25888L3.12607 9.49412C2.8995 9.72444 2.75887 10.0262 2.73543 10.3519L2.52448 12.6789C2.50104 12.9728 2.60261 13.2587 2.80574 13.4731C2.99325 13.6637 3.23545 13.767 3.49327 13.767H3.57921L5.86836 13.5525C6.18868 13.5208 6.48557 13.3778 6.71214 13.1475L13.8296 5.91224L10.2357 2.25888H10.2435ZM17.5485 16.3005C17.5485 15.9749 17.2829 15.7048 16.9625 15.7048H1.33694C1.01661 15.7048 0.750977 15.9749 0.750977 16.3005C0.750977 16.6261 1.01661 16.8961 1.33694 16.8961H16.9625C17.2829 16.8961 17.5485 16.6261 17.5485 16.3005Z" fill="white" />
            </svg>

        </div>
    );
};

const Moon = ({ ...otherProps }) => {
    return (
        <div className=''>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  {...otherProps}>
                <path fill="none" d="M0 0h24v24H0z"></path><path d="M12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM11 1H13V4H11V1ZM11 20H13V23H11V20ZM3.51472 4.92893L4.92893 3.51472L7.05025 5.63604L5.63604 7.05025L3.51472 4.92893ZM16.9497 18.364L18.364 16.9497L20.4853 19.0711L19.0711 20.4853L16.9497 18.364ZM19.0711 3.51472L20.4853 4.92893L18.364 7.05025L16.9497 5.63604L19.0711 3.51472ZM5.63604 16.9497L7.05025 18.364L4.92893 20.4853L3.51472 19.0711L5.63604 16.9497ZM23 11V13H20V11H23ZM4 11V13H1V11H4Z"></path>
            </svg>
        </div>
    );
};

const Sun = ({ ...otherProps }) => {
    return (
        <div className='' >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...otherProps}>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M10 7C10 10.866 13.134 14 17 14C18.9584 14 20.729 13.1957 21.9995 11.8995C22 11.933 22 11.9665 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.0335 2 12.067 2 12.1005 2.00049C10.8043 3.27098 10 5.04157 10 7ZM4 12C4 16.4183 7.58172 20 12 20C15.0583 20 17.7158 18.2839 19.062 15.7621C18.3945 15.9187 17.7035 16 17 16C12.0294 16 8 11.9706 8 7C8 6.29648 8.08133 5.60547 8.2379 4.938C5.71611 6.28423 4 8.9417 4 12Z" ></path>
            </svg>
        </div>
    );
};

const Profile = ({ ...otherProps }) => {
    return (
        <div className='focus-color'>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...otherProps} fill="currentColor" >
                <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM6.02332 15.4163C7.49083 17.6069 9.69511 19 12.1597 19C14.6243 19 16.8286 17.6069 18.2961 15.4163C16.6885 13.9172 14.5312 13 12.1597 13C9.78821 13 7.63095 13.9172 6.02332 15.4163ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z">
                </path>
            </svg>
        </div>
    );
};

const LogOutsvg = ({ ...otherProps }) => {
    return (
        <div className='focus-color'>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...otherProps} fill="currentColor">
                <path d="M5 22C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V6H18V4H6V20H18V18H20V21C20 21.5523 19.5523 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z">
                </path>
            </svg>
        </div>
    );
};


const CrossImg = ({ size = 14, ...otherProps }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2905 2.54291L20.1405 7.35291C21.2055 8.10138 21.8396 9.32124 21.8405 10.6229V17.7529C21.8378 18.8854 21.3854 19.9704 20.5828 20.7693C19.7801 21.5682 18.693 22.0156 17.5605 22.0129H6.28049C5.14801 22.0156 4.06087 21.5682 3.25821 20.7693C2.45555 19.9704 2.00314 18.8854 2.00049 17.7529V10.6229C2.00134 9.32124 2.63552 8.10138 3.70049 7.35291L10.5505 2.54291C11.3204 1.81903 12.5206 1.81903 13.2905 2.54291ZM19.5221 19.7087C20.0435 19.1911 20.3378 18.4876 20.3405 17.7529V10.6229C20.3265 9.82531 19.9327 9.08228 19.2805 8.62291L12.4305 3.81291L12.3205 3.73291C12.2152 3.62541 12.071 3.56483 11.9205 3.56483C11.77 3.56483 11.6258 3.62541 11.5205 3.73291L11.4105 3.81291L4.56049 8.62291C3.9083 9.08228 3.5145 9.82531 3.50049 10.6229V17.7529C3.50314 18.4876 3.79752 19.1911 4.31887 19.7087C4.84022 20.2263 5.54584 20.5156 6.28049 20.5129H17.5605C18.2951 20.5156 19.0008 20.2263 19.5221 19.7087Z" fill="#D2D6D9" />
            <path d="M17.9905 11.0129L12.4505 14.8829C12.2865 15.047 12.0613 15.1349 11.8295 15.1254C11.5977 15.1159 11.3805 15.0098 11.2305 14.8329L5.85048 11.0129C5.63438 10.826 5.33084 10.7781 5.06769 10.8894C4.80454 11.0007 4.6275 11.2519 4.6111 11.5372C4.59471 11.8224 4.74182 12.0922 4.99048 12.2329L10.2905 16.0129C10.7251 16.4175 11.2967 16.6426 11.8905 16.6429C12.4489 16.6288 12.9813 16.4037 13.3805 16.0129L18.8505 12.1929C19.1649 11.9602 19.2477 11.5248 19.0405 11.1929C18.7981 10.8566 18.331 10.7765 17.9905 11.0129Z" fill="#D2D6D9" />
        </svg>
    );
};


// fill="#878a99" 

export { SearchIcon, EmailIcon, PasswordIcon, UserIcon, DownloadsIcon, BackIcon, EyeIcon, EyeSlashIcon, RupeesIcon, EditIcon, Moon, Sun, Profile, LogOutsvg, CrossImg }