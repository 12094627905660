import StockCatagoryImg1 from '../assets/images/light/stock-category-1.png';
import StockCatagoryImgDark1 from '../assets/images/dark/stock-category-1.png';
import StockCatagoryImg2 from '../assets/images/light/stock-category-2.png';
import StockCatagoryImgDark2 from '../assets/images/dark/stock-category-2.png';
import StockCatagoryImg3 from '../assets/images/light/stock-category-3.png';
import StockCatagoryImgDark3 from '../assets/images/dark/stock-category-3.png';

export const MomentumPerformanceAnalysis = [
  { id: 1, title: 'Top Gainers (Daily)', description: 'Stocks offering a high dividend', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Top Gainers (Weekly)', description: 'Stocks with the highest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Top Gainers (Monthly)', description: 'Stocks with the highest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Top Losers (Daily)', description: 'Stocks with the largest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Top Losers (Weekly)', description: 'Stocks with the largest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Top Losers (Monthly)', description: 'Stocks with the largest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: '52-Week High', description: 'Stocks that have dropped to their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: '52-Week Low', description: 'Stocks that have dropped to their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Stocks Near 52 (Week High)', description: 'Stocks with the largest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stocks Near 52(Week Low)', description: 'Stocks that are approaching their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'High Price Momentum Stocks', description: 'Stocks showing strong price', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'Low Price Momentum Stocks', description: 'Stocks with weak price momentum', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 13, title: 'Stocks with Consistent ', description: 'Companies that have delivered', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 14, title: 'Top Gainers (Year-to-Date)', description: 'Stocks that are approaching their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 15, title: 'Top Losers (Year-to-Date)', description: 'Stocks with the largest percentage', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 16, title: 'Stocks with Reversal Patterns', description: 'Stocks showing patterns indicating', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 17, title: 'Stocks with Breakout Patterns', description: 'Stocks that have broken out of', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Trading Volume & Activity 
export const TradingVolumeActivity = [
  { id: 1, title: 'High Volume Stocks ', description: 'Stocks that have traded with ', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 2, title: 'Low Volume Stocks', description: 'Stocks that have traded with ', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 3, title: 'Only Buyers', description: 'Stocks with heavy buying activity', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 4, title: 'Only Sellers', description: 'Stocks with heavy selling activity', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 5, title: 'Unusual Volume Spikes', description: 'Stocks that have experienced ', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 6, title: 'Stocks with Increasing', description: 'Stocks showing a consistent', image: StockCatagoryImg3, darkimg: StockCatagoryImgDark3 },
  { id: 7, title: 'Stocks with Decreasing', description: 'Stocks showing a consistent', image: StockCatagoryImg3, darkimg: StockCatagoryImgDark3 },
  { id: 8, title: 'High Turnover Ratio Stocks', description: 'Stocks with a high turnover ratio', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 9, title: 'Low Turnover Ratio Stocks', description: 'Stocks with a low turnover ratio', image: StockCatagoryImg3, darkimg: StockCatagoryImgDark3 },
  { id: 10, title: 'Stocks with Low Float', description: 'Companies with a small number', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 11, title: 'Stocks with High Insider', description: 'Stocks with significant inside', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
  { id: 12, title: 'Stocks with Heavy', description: 'Companies with significant buying', image: StockCatagoryImg2, darkimg: StockCatagoryImgDark2 },
];

// Investor Sentiment & Insider Activity
export const InvestorSentimentInsiderActivity = [
  { id: 1, title: 'FII Buying', description: 'Stocks that have experienced', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'FII Selling', description: 'Stocks that have witnessed', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'DII Buying', description: 'Stocks that have experienced', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'DII Selling', description: 'Stocks that have seen significant', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Insider Buying', description: 'Stocks where company insiders are', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Insider Selling', description: 'Stocks where company insiders are', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with High ', description: 'Stocks with a high level of short', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Stocks with Short Covering', description: 'Stocks where short sellers are buying', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Most Discussed Stocks', description: 'Stocks that are being widely ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stocks with High Analyst', description: 'Stocks that have received multiple', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Stocks with High Analyst', description: 'Stocks that have received multiple', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'Stocks with Positive Social', description: 'Stocks trending positively on social', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 13, title: 'Stocks with Negative Social', description: 'Stocks trending negatively on social', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 14, title: 'Stocks with High Hedge', description: 'Companies with significant hedge', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Valuation-Based Analysis
export const ValuationBasedAnalysis = [
  { id: 1, title: 'Overvalued Stocks', description: 'Stocks trading at prices higher than', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Undervalued Stocks', description: 'Stocks trading below their intrinsic', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Low P/E Ratio Stocks', description: 'Stocks with a low Price-to-Earnings ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'High P/E Ratio Stocks', description: 'Stocks with a high P/E ratio', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Low Price-to-Book Ratio', description: 'Stocks with a low Price-to-BooK', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'High Price-to-Book Ratio', description: 'Stocks with a high P/B ratio, indicating ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with High PEG Ratio', description: 'Stocks with a high Price/Earnings-to', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Stocks with Low PEG Ratio', description: 'Stocks with a low PEG ratio, suggesting', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Stocks with High Dividend', description: 'Stocks with a low Price-to-BooK', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stocks Trading Below Net', description: 'Companies trading below their net', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Stocks with High Price-to', description: 'Companies with a high Price-to-Sales', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'Stocks with Low Price-to', description: 'Companies with a low P/S ratio', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Dividend & Income-Based Analysis
export const DividendIncomeBasedAnalysis = [
  { id: 1, title: 'High Dividend Yield Stocks', description: 'Stocks offering a high dividend', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Low Dividend Yield Stocks', description: 'Stocks with low dividend yields', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'High Dividend Payout Ratio', description: 'Stocks with a high percentage of ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Low Dividend Payout Ratio', description: 'Stocks with a low payout ratio', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Dividend Aristocrats', description: 'Companies that have consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Defensive Dividend Stocks', description: 'Companies that offer reliable ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Dividend Reinvestment', description: 'Companies offering dividend', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Stocks with Special Dividends', description: 'Companies that have declared special', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Stocks with High Yield on', description: 'Companies where the current ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Financial Health & Growth Indicators 
export const FinancialHealthGrowthIndicators = [
  { id: 1, title: 'Debt Reduction Leaders', description: 'Companies that have significantly', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Companies Creating New', description: 'Companies consistently reaching', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Growth Without Dilution', description: 'Companies expanding their operations', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Capacity Expansion', description: 'Companies that are increasing their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Low on 10-Year Average', description: 'Companies whose current earnings', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Stocks with High ROE', description: 'Companies with high Return on', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with High ROA', description: 'Companies with high Return on ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Companies with Increasing', description: 'Companies that are consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Companies with Decreasing', description: 'Companies that are lowering their', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stocks with Strong Free', description: 'Companies with strong free cash', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Stocks with High EBITDA', description: 'Companies with high EBITDA margins', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'Stocks with Low EBITDA', description: 'Companies with low EBITDA', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 13, title: 'Stocks with High Gross', description: 'Companies with high gross margins', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 14, title: 'Stocks with Low Gross ', description: 'Companies with low gross margins', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 15, title: 'Stocks with Strong Earnings', description: 'Companies that have consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 16, title: 'Stocks with Earnings Misses', description: 'Companies that have consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 17, title: 'Stocks with High Return on', description: 'Companies with a high ROIC', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 18, title: 'Stocks with Low Return on', description: 'Companies with a low ROIC', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 19, title: 'Stocks with Strong Debt', description: 'Companies with strong ratios of', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 20, title: 'Companies with Improving', description: 'Companies that are consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 21, title: 'Stocks with Expanding ', description: 'Companies that are gaining market', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 22, title: 'Companies with High', description: 'Companies that retain a large portion', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 23, title: 'Companies with High', description: 'Companies heavily investing in R&D', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Volatility & Risk-Based Analysis 
export const VolatilityRiskBasedAnalysis = [
  { id: 1, title: 'High Volatility Stocks', description: 'Stocks with significant price swings', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Low Volatility Stocks', description: 'Stable stocks with minimal price', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Stocks with High Beta', description: 'Stocks with high beta, indicatin', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Stocks with Low Beta', description: 'Stocks with low beta, indicating', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Low on 10-Year Average', description: 'Companies whose current earnings', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Stocks with High ROE', description: 'Companies with high Return on', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with High ROA', description: 'Companies with high Return on ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Companies with Increasing', description: 'Companies that are consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Speculative High-Growth', description: 'High-risk, high-reward stocks with', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Penny Stocks', description: 'Low-priced stocks with a high', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Stocks with Low Correlation', description: 'Companies that have low correlation', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'Stocks with High Skewness', description: 'Companies with abnormal return', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 13, title: 'Stocks with High Drawdown', description: 'Companies with a history of significant', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];


// Sector & Thematic-Based Analysis
export const SectorThematicBasedAnalysis = [
  { id: 1, title: 'Defensive Stocks', description: 'Companies in sectors like utilities', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Cyclical Stocks', description: 'Companies in sectors like automotive', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Technology Growth Stocks', description: 'Tech companies with high growth', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Biotech Innovation Stocks', description: 'Biotech firms focusing on cutting', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Energy Sector Leaders', description: 'Companies in the energy sector', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Renewable Energy Stocks', description: 'Companies involved in renewable', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Infrastructure Stocks', description: 'Companies involved in infrastructure', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Real Estate Investment', description: 'Companies that own and operate', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'High Growth Emerging', description: 'Companies in emerging markets with', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stable Developed Market', description: 'Companies in developed markets', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Blue-Chip Stocks', description: 'Well-established companies with', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 12, title: 'ESG Leaders', description: 'Companies that score highly on ESG', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 13, title: 'Consumer Discretionary', description: 'Companies in the consumer', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 14, title: 'Healthcare Innovators', description: 'Companies leading innovation in the', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 15, title: 'Fintech Disruptors', description: 'Companies in the financial technology', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 16, title: 'Artificial Intelligence Stocks', description: 'Companies developing AI technologies', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 17, title: 'Cannabis Industry Leaders', description: 'Companies in the cannabis industry', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 18, title: 'Electric Vehicle (EV) Stocks', description: 'Companies involved in the production', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 19, title: '5G Technology Stocks', description: 'Companies involved in the ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 20, title: 'E-commerce Growth Stocks', description: 'Companies leading the e-commerce', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 21, title: 'Telemedicine Stocks', description: 'Companies in the telemedicine space', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Corporate Actions & Governance 
export const CorporateActionsGovernance = [
  { id: 1, title: 'Stocks with Strong', description: 'Companies that are actively', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Dividend Aristocrats', description: 'Companies that have consistently', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Stocks with Upcoming', description: 'Companies with planned corporate', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'New IPOs', description: 'Newly listed companies, offering ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Stocks with High Insider', description: 'Companies where insiders own', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Stocks with Strong', description: 'Companies with governance structures', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with High', description: 'Companies with high scores in corporat', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Stocks with Anti-Takeover', description: 'Companies that have implemented', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Technical Analysis-Based 
export const TechnicalAnalysisBased = [
  { id: 1, title: 'Stocks with Strong', description: 'Stocks showing positive signals', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Stocks with Negative', description: 'Stocks showing negative signals from', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Stocks Breaking Above', description: 'Stocks that have broken through key', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Stocks Falling Below Support', description: 'Stocks that have fallen below key', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Stocks with Golden Crosses', description: 'Companies where the 50-day', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Stocks with Death Crosses', description: 'Companies where the 50-day moving ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks in Overbought', description: 'Stocks that have broken through key', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 8, title: 'Stocks in Oversold Territory', description: 'Companies with RSI below 30', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 9, title: 'Stocks with High Insider', description: 'Companies with a bullish crossover', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 10, title: 'Stocks with MACD Bearish', description: 'Companies with a bearish crossover', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 11, title: 'Stocks Breaking Out of', description: 'Companies whose prices have moved', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Special Situations
export const SpecialSituations = [
  { id: 1, title: 'M&A Targets', description: 'Companies that are potential', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Turnaround Stocks', description: 'Companies that have struggled but', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Spin-Offs', description: 'Companies that have recently spun ', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Stocks with Legal or', description: 'Companies facing legal or regulatory', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Distressed Debt Stocks', description: 'Companies with distressed debt', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Bankruptcy Candidates', description: 'Companies at risk of bankruptcy', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Stocks with Activist Investor', description: 'Companies targeted by activist', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

// Event-Driven Analysis 
export const EventDrivenAnalysis = [
  { id: 1, title: 'Earnings Season Watchlist', description: 'Stocks to watch during earnings', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 2, title: 'Dividend Ex-Dividend Date', description: 'Stocks nearing their ex-dividend date', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 3, title: 'Stocks with Upcoming', description: 'Companies that are about to launch', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 4, title: 'Stocks with Upcoming', description: 'Companies hosting investor days', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 5, title: 'Stocks with Upcoming FDA', description: 'Biotech or pharmaceutical companies', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 6, title: 'Stocks with Upcoming Patent', description: 'Companies facing upcoming patent', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
  { id: 7, title: 'Companies Facing Proxy', description: 'Companies where shareholders are', image: StockCatagoryImg1, darkimg: StockCatagoryImgDark1 },
];

