import React from "react";
import googleColorIcon from '../../assets/images/general/google-color-icon.png';
import AuthDetails from "../../components/CommanLayout/AuthDetails";
import { EmailIcon, PasswordIcon, UserIcon } from "../../assets/images/svgfiles/Svg";


const Signup = () => {
  return (
    <div className=" auth-section h-full py-12 bg-overley grow content-center">
       <div className="container mx-auto px-4">
         <div className="login-card xl:p-12 md:p-10 sm:p-8 p-6 sm-moblie:p-[17px] sm-moblie:rounded-xl">
           <div className="grid xl:grid-cols-5 lg:grid-cols-6 grid-cols-1 lg:gap-5 sm:gap-10 gap-6 items-center">
             <div className="xl:col-span-3 lg:col-span-3 col-span-1">
             <AuthDetails pagehead="Get Started !" description="Over 10 lakh investors use Screener for finding and tracking stock ideas." questions="Already registered? " redirect="/login" pagename="Login here."/>
             </div>
             <div className="xl:col-span-2 lg:col-span-3 col-span-1">
               <div className=" login-form sm-moblie:rounded-xl">
                 <div className=" text-center ">
                   <h3 className="pb-3 text-7xl text-dark-gray font-semibold sm-moblie:text-4xl">Register</h3>
                   <p className=" text-dark-gray text-base pb-8 sm-moblie:text-sm">How to i get started lorem ipsum dolor at?</p>
                 </div>
                 <form >
                   <div className="relative">
                      {/* <img src={userIcon} alt="email-icon" className=" top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2"/> */}
                      <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                        <UserIcon/>
                      </div>
                      <input id="fullname" name="fullname" type="text" placeholder="Fullname" autoComplete="fullname" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition"/>
                   </div>
                   <div className="mt-6 relative">
                      {/* <img src={emailIcon} alt="email-icon" className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2"/> */}
                      <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                        <EmailIcon/>
                      </div>
                     <input type="text" name="email" id="first-name" placeholder="Email" autoComplete="given-name" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray"/>
                   </div>
                   <div className="mt-6 relative">
                      {/* <img src={passwordIcon} alt="email-icon" className=" top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2"/> */}
                      <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                        <PasswordIcon/>
                      </div>
                     <input id="password" name="password" type="Password" placeholder="Password" autoComplete="fullname" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition"/>
                   </div>
                   <div className="mt-6 text-center">
                     <button className=" px-6 py-3 btn-gradient uppercase text-sm font-medium rounded-full">Register Now</button>
                   </div>
                 </form>
                 <div className="text-center login-dotline-title relative sm:my-5 my-3">
                   <p className="text-dark-gray "><span className="font-bold">Login</span> with Others</p>
                 </div>
                 <div className=" text-center">
                   <button className=" px-6 py-3 btn-gray text-sm rounded-full  flex items-center justify-center w-full"><img src={googleColorIcon} alt="email-icon" className="me-2 w-6 h-6"/> <p className="text-dark-gray">Login with google</p></button>
                 </div>
               </div>  
             </div>
           </div>
         </div>
       </div>
    </div>
    
 )
};

export default Signup;
