import React from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { LogOutsvg, Profile } from '../../../assets/images/svgfiles/Svg';

export default function ProfileDropDown() {
    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <MenuButton className="light-dark-themetoogle inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 sm-moblie:px-1 sm-moblie:py-1 text-sm  text-dark-gray shadow-sm items-center sm-moblie:text-xs">
                        <Profile height="20px" width="20px" className="sm-moblie:h-4 sm-moblie:w-4"/> Wayne 
                    </MenuButton>
                </div>
                <MenuItems transition className="profile-dropdown absolute right-0 z-10 mt-2 w-44 sm-moblie:w-28 origin-top-right rounded-lg transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border-2 border-bd-light">
                    <div className="py-1">
                        <MenuItem>
                            <Link to='/profile' className="px-4 py-2 sm-moblie:px-2 sm-moblie:py-1 text-sm sm-moblie:text-xs text-dark-gray data-[focus]:text-[#9187de] flex gap-2">
                                <Profile height="24px" width="24px" className="sm-moblie:h-4 sm-moblie:w-4"/>Profile</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='' className="px-4 py-2 sm-moblie:px-2 sm-moblie:py-1 text-sm sm-moblie:text-xs text-dark-gray data-[focus]:text-[#9187de] flex gap-2">
                                <LogOutsvg height="20px" width="20px" className="sm-moblie:h-4 sm-moblie:w-4"/> Sign out</Link>
                        </MenuItem>
                    </div>
                </MenuItems>
            </Menu>
        </div>
    )
}
