import React from "react";
import logo from '../../assets/images/light/logo.png';
import Darklogo from '../../assets/images/dark/logo.png';
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <footer className=" footer-section">
        <div className=" container mx-auto px-4">
          <div className=" grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5">
            <div className=" lg:col-span-4 md:col-span-2 sm:col-span-2 col-span-1">
              <img src={logo} alt="logo" className=" h-8 object-contain md:mb-5 mb-4 light-img-hs"/>
              <img src={Darklogo} alt="logo" className=" h-8 object-contain md:mb-5 mb-4 dark-img-hs"/>
              <p className="mb-2 text-dark-gray text-base font-normal sm-moblie:text-sm">Stock analysis and screening tool</p>
              <p className="mb-2 text-dark-gray text-base font-normal sm-moblie:text-sm">Mittal Analytics Private Ltd © 2009-2024 <br></br> Made with in India.</p>
              <p className="mb-2 text-dark-gray text-base font-normal sm-moblie:text-sm">Data provided by C-MOTS Internet Technologies Pvt Ltd</p>
              <p className="pt-4 text-dark-gray text-base font-normal hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="" className="underline">Terms & Privacy</Link></p>

            </div>
            <div className="col-span-1">
              <h4 className=" text-th-black text-2xl font-semibold mb-3">Product</h4>
                <ul className="">
                  <li className=" text-base text-dark-gray py-1 hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="">Premium</Link></li>
                  <li className=" text-base text-dark-gray py-1 hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="">What's new?</Link></li>
                  <li className=" text-base text-dark-gray py-1 hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="">Learn</Link></li>
                </ul>
            </div>
            <div className="col-span-1">
              <h4 className=" text-th-black text-2xl font-semibold mb-3">Team</h4>
                <ul className="">
                  <li className=" text-base text-dark-gray py-1 hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="">About us</Link></li>
                  <li className=" text-base text-dark-gray py-1 hover:text-purple transition duration-200 sm-moblie:text-sm"><Link to="">Support</Link></li>
                </ul>
            </div>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
