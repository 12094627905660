import ChangePassword from "../pages/Authentication/ChangePassword";
import CreateWatchlist from "../pages/Authentication/CreateWatchlist";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Login from "../pages/Authentication/Login";
import Signup from "../pages/Authentication/Signup";
import StockData from "../pages/Stock-data/StockData";
import Dashboard from "../pages/Dashboard/Home";
import StockCatagory from "../pages/Stock-catagory/StockCatagory";
import Watchlist from "../pages/Watchlist/Watchlist";
import ProfileDetails from "../pages/Profile/ProfileDetails";

export const publicRoutes = [
  {
    path: "/login", component: <Login />,
    meta: {
      title: "Login | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/signup", component: <Signup />,
    meta: {
      title: "Sign Up | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/change-password", component: <ChangePassword />,
    meta: {
      title: "Change-Password | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/forgot-password", component: <ForgotPassword />,
    meta: {
      title: "Forgot-Password | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/create-watchlist", component: <CreateWatchlist />,
    meta: {
      title: "Create-WatchList | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
];

export const privateRoutes = [
  {
    path: "/home", component: <Dashboard />,
    meta: {
      title: "Home | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/", component: <Dashboard />,
    meta: {
      title: "Home | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/profile", component: <ProfileDetails />,
    meta: {
      title: "Home | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/stock-data", component: <StockData />,
    meta: {
      title: "Stock-Data | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/watchlist/:id", component: <Watchlist />,
    meta: {
      title: "Watchlist | stock-Analysis",
      description: "Description for dashboard page",
    }
  },
  {
    path: "/stock-catagory", component: <StockCatagory />,
    meta: {
      title: "Stock-Catagory | stock-Analysis",
      description: "Description for dashboard page",
    }
  }
];
