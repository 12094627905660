import React from 'react';
import { Link } from 'react-router-dom';

const CardGrid = ({ title, data }) => {
  return (

    <div className="">
      <div className="pb-3">
        <h4 className="mb-0 text-th-black font-semibold text-4xl perpul-line relative pb-2 sm-moblie:text-tiny">{title}</h4>
      </div>
      <div className="card">
        <div className="card-body py-8 sm-moblie:py-5">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-7 sm-moblie:gap-3">
            {data.map((category, index) => (
              <div key={index} className="col-span-1">
                {/* <Link to={`/watchlist/${id}`}> */}
                <Link to={`/watchlist/${category.id}`}>
                  <div className="p-4 blur-filter border border-bd-light rounded-2xl flex items-center">
                    <img
                      src={category.image}
                      alt="logo"
                      className="w-14 h-14 object-contain me-2 light-img-hs"
                    />
                    <img
                      src={category.darkimg}
                      alt="logo"
                      className="w-14 h-14 object-contain me-2 dark-img-hs"
                    />
                    <div>
                      <h4 className="text-base font-medium mb-1 line-clamp-1 text-ellipsis">{category.title}</h4>
                      <p className="text-muted text-xs line-clamp-1 text-ellipsis">{category.description}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardGrid;
