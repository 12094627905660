import React from "react";
import { Route, Routes } from "react-router-dom";
import { privateRoutes } from "./allRoutes";
import { publicRoutes } from "./allRoutes";
import MainLayout from "../Layout/MainLayout/MainLayout";

const index = () => {
  return (
    <>
      <Routes>
        {privateRoutes.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              index
              element={<MainLayout meta={item.meta} >{item.component}</MainLayout>}
            ></Route>
          );
        })}
     
        {publicRoutes.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={<MainLayout meta={item.meta}>{item.component}</MainLayout>}
            ></Route>
          );
        })}
      </Routes>

    </>
  );
};

export default index;

