import React, { useState } from "react";
import { Link } from 'react-router-dom';
import {  EmailIcon } from "../../assets/images/svgfiles/Svg";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";

const Login = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  return (
    <React.Fragment>
   <div className=" auth-section h-full py-12 bg-overley grow content-center">
      <div className="container mx-auto px-4">
        <div className="login-card xl:p-12 md:p-4 sm:p-8 p-6 sm-moblie:p-[17px] sm-moblie:rounded-xl">
          <div className="grid xl:grid-cols-5 lg:grid-cols-5 grid-cols-1 lg:gap-5 sm:gap-10 gap-6 items-center">
         
            <div className="xl:col-span-4 lg:col-span-4 col-span-1">
              <div className=" login-form sm-moblie:rounded-xl">            
                  <h3 className="pb-3 text-5xl text-dark-gray font-semibold sm-moblie:text-4xl">Profile Setting</h3>                
                <form onSubmit={hasFormSubmit}>
                <div className="relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <EmailIcon/>
                    </div>
                    <input  type="email" value={email} name="email" id="email" placeholder="Registered email" autoComplete="email" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray" onChange={(e)=>setEmail(e.target.value)} />
                  </div>  
                  <div className="mt-6 relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <EmailIcon/>
                    </div>
                    <input type="username" value={username} name="username" id="username" placeholder="Wayne" autoComplete="username" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray" onChange={(e)=>setUsername(e.target.value)} />
                  </div>               
                  <div className="sm:my-6 my-5 text-end">
                    <Link to="/forgot-password" className=" text-purple text-sm font-Montserrat">Forgot Password ?</Link>
                  </div>
                  <div className="text-center">
                    <button className=" px-6 py-3 btn-gradient uppercase text-sm font-medium rounded-full">Login Now</button>
                  </div>
                </form>            
              </div>  
            </div>
          </div>
        </div>
      </div>
   </div>
   </React.Fragment>
   
)
};

export default Login;
