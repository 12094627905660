import React, { useState } from "react";
import googleColorIcon from '../../assets/images/general/google-color-icon.png';
import { Link, useNavigate } from 'react-router-dom';
import AuthDetails from "../../components/CommanLayout/AuthDetails";
import { EmailIcon, EyeIcon, EyeSlashIcon } from '../../assets/images/svgfiles/Svg'
import { PasswordIcon } from '../../assets/images/svgfiles/Svg'


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldpassword: false,
  });
  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "test@example.com" && password === "password") {
      navigate('/home'); 
    } else {
      console.log('invelid');
      alert("Invalid credentials");
    }
  };
  return (
    <React.Fragment>
   <div className=" auth-section h-full py-12 bg-overley grow content-center">
      <div className="container mx-auto px-4">
        <div className="login-card xl:p-12 md:p-10 sm:p-8 p-6 sm-moblie:p-[17px] sm-moblie:rounded-xl">
          <div className="grid xl:grid-cols-5 lg:grid-cols-6 grid-cols-1 lg:gap-5 sm:gap-10 gap-6 items-center">
            <div className="xl:col-span-3 lg:col-span-3 col-span-1">
            <AuthDetails pagehead="Welcome back!" description="Login to your account using your email and password." questions="Don't have an account?" redirect="/signup" pagename="Register for free."/>
            </div>
            <div className="xl:col-span-2 lg:col-span-3 col-span-1">
              <div className=" login-form sm-moblie:rounded-xl">
                <div className=" text-center ">
                  <h3 className="pb-3 text-7xl text-dark-gray font-semibold sm-moblie:text-4xl">Login</h3>
                  <p className=" text-dark-gray text-base pb-8 sm-moblie:text-sm">How to i get started lorem ipsum dolor at?</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <EmailIcon/>
                    </div>
                    <input type="email" name="email" id="email" placeholder="Email" autoComplete="email" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray" onChange={(e)=>setEmail(e.target.value)} />
                  </div>
                  <div className="mt-6 relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <PasswordIcon/>
                    </div>                    
                    <input type={passwordVisibility.oldpassword ? 'text' : 'password'} className="block w-full text-dark-gray rounded-full p-3 px-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition bg-transparent" autoComplete="password" placeholder="Enter your password" onChange={(e)=>setPassword(e.target.value)}/>
                    <button type="button" onClick={() => togglePasswordVisibility('oldpassword')} className=" top-0 end-3 bottom-0 absolute focus-visible:outline-none" >
                      <div aria-label={passwordVisibility.oldpassword ? "Hide password" : "Show password"}>{passwordVisibility.oldpassword ? <EyeIcon /> : <EyeSlashIcon />}</div>
                    </button>
                  </div>
                  <div className="sm:my-6 my-5 text-end">
                    <Link to="/forgot-password" className=" text-purple text-sm font-Montserrat">Forgot Password ?</Link>
                  </div>
                  <div className="text-center">
                    <button className=" px-6 py-3 btn-gradient uppercase text-sm font-medium rounded-full">Login Now</button>
                  </div>
                </form>
                <div className="text-center login-dotline-title relative sm:my-5 my-3">
                  <p className="text-dark-gray "><span className=" font-bold ">Login</span> with Others</p>
                </div>
                <div className=" text-center">
                  <button className=" px-6 py-3 btn-gray text-sm rounded-full  flex items-center justify-center w-full"><img src={googleColorIcon} alt="email-icon" className="me-2 w-6 h-6"/> <p className="text-dark-gray">Login with google</p></button>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
   </div>
   </React.Fragment>
   
)
};

export default Login;
