import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function MetaTag({ title, description, type, ogDescription, url, ogSiteName }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={ogSiteName || "My Website"} />
    </Helmet>
  );
};

