import React from "react";
import watchlistBanner from '../../assets/images/general/create-watchlist.png';
import { Link } from 'react-router-dom';
import AuthDetails from "../../components/CommanLayout/AuthDetails";
import { BackIcon, DownloadsIcon } from "../../assets/images/svgfiles/Svg";


const CreateWatchlist = () => {
  return (
    <div className=" auth-section h-full py-12 bg-overley grow content-center">
      <div className="container mx-auto px-4">
        <div className="login-card xl:p-12 md:p-10 sm:p-8 p-4 mb-5">
          <div className="grid lg:grid-cols-5 grid-cols-1 sm:gap-10 gap-6 items-center">
            <div className="lg:col-span-3 col-span-1">
              <AuthDetails pagehead="Create new watchlist" description="Stocks in additional watchlists don't clutter your news feed." />
              <form >
                <div className="relative">
                  <input type="text" name="first-name" id="first-name" placeholder="name" autoComplete="given-name" className="block w-full bg-transparent text-dark-gray rounded-full p-3 pl-4 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition" />
                </div>
                <div className="xl:mt-8 mt-6 flex items-center justify-between flex-wrap">
                  <button className="px-6 py-3 btn-gradient uppercase text-sm font-medium rounded-full me-2 sm:mb-0 mb-2">Save</button>
                  <button className="px-6 py-3 uppercase text-sm font-medium rounded-full border flex items-center">
                    {/* <img src={downloadIcon} alt="" className="" /> */}
                    <div className=" h-5 w-5 me-2">
                      <DownloadsIcon/>
                    </div>
                    <span className=" text-th-black">Import companies</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="lg:col-span-2 col-span-1">
              <div className="">
                <img src={watchlistBanner} alt="" className="w-full h-full lg:max-h-96 max-h-80 object-contain"/>
              </div>
            </div>
          </div>
        </div>
        <div className=" text-end max-w-6xl mx-auto">
          <Link to="/" className=" text-dark-gray flex items-center justify-end">
            <BackIcon/>
            <span className=" ms-2">
              Go Back
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
};

export default CreateWatchlist;
