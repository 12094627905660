import ScrollToTop from "./components/element/ScrollToTop";
import Route from "./routes/index";

function App() {
  return (
    <>
         <ScrollToTop/>
      <Route />
    </>
  );
}

export default App;
