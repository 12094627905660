import React from 'react'
import { Link } from 'react-router-dom'

export default function AuthDetails({description,pagehead,questions,redirect,pagename}) {
    return (
        <>
            <div className="flex justify-center flex-col">
                <h2 className="lg:pb-6 pb-2 2xl:text-9xl sm:text-8xl text-6xl text-purple font-bold font-Montserrat lg:max-w-96 sm-moblie:text-4xl">{pagehead}</h2>
                <p className=" text-dark-gray 2xl:text-2xl md:text-xl text-base font-light  lg:max-w-lg">{description}</p>
                <p className=" font-light 2xl:text-2xl md:text-xl text-base text-dark-gray 2xl:pt-18 lg:pt-16 pt-4">{questions} <Link to={redirect} className=" text-purple">{pagename}</Link></p>
            </div>
        </>
    )
}
