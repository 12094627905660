import React, { useState } from "react";
import googleColorIcon from '../../assets/images/general/google-color-icon.png';
import AuthDetails from "../../components/CommanLayout/AuthDetails";
import { EyeIcon, EyeSlashIcon, PasswordIcon } from "../../assets/images/svgfiles/Svg";

const ChangePassword = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldpassword: false,
    newpassword: false,
    confirmpassword: false,
  });
  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  return (
    <div className="auth-section h-full py-12 bg-overley grow content-center">
      <div className="container mx-auto px-4">
        <div className="login-card xl:p-12 md:p-10 sm:p-8 p-6 sm-moblie:p-[17px] sm-moblie:rounded-xl">
          <div className="grid xl:grid-cols-5 lg:grid-cols-6 grid-cols-1 lg:gap-5 sm:gap-10 gap-6 items-center">
            <div className="xl:col-span-3 lg:col-span-3 col-span-1">
              <AuthDetails pagehead="Change Password ?" description="Set the new password for your account so you can login and access all featuress." />
            </div>
            <div className="xl:col-span-2 lg:col-span-3 col-span-1">
              <div className=" login-form sm-moblie:rounded-xl">
                <div className=" text-center ">
                  <h3 className="pb-3 text-6xl text-dark-gray font-semibold sm-moblie:text-4xl">Change Password ?</h3>
                  <p className=" text-dark-gray text-base pb-8 sm-moblie:text-sm">How to i get started lorem ipsum dolor at?</p>
                </div>
                <form >
                  <div className="relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <PasswordIcon />
                    </div>
                    <input type={passwordVisibility.oldpassword ? 'text' : 'password'} className="block w-full text-dark-gray rounded-full p-3 px-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition bg-transparent" autoComplete="password" placeholder="Enter your password" />
                    <button type="button" onClick={() => togglePasswordVisibility('oldpassword')} className=" top-0 end-3 bottom-0 absolute focus-visible:outline-none" >
                      <div aria-label={passwordVisibility.oldpassword ? "Hide password" : "Show password"}>{passwordVisibility.oldpassword ? <EyeIcon /> : <EyeSlashIcon />}</div>
                    </button>
                  </div>
                  <div className="mt-6 relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <PasswordIcon />
                    </div>
                    <input name="new-password" type={passwordVisibility.newpassword ? 'text' : 'password'} autoComplete="new-password" placeholder="Enter New password" className="block w-full text-dark-gray rounded-full p-3 px-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition bg-transparent" />
                    <button type="button" onClick={() => togglePasswordVisibility('newpassword')} className=" top-0 end-3 bottom-0 absolute focus-visible:outline-none" >
                      <div aria-label={passwordVisibility.newpassword ? "Hide password" : "Show password"}>{passwordVisibility.newpassword ? <EyeIcon /> : <EyeSlashIcon />}</div>
                    </button>
                  </div>
                  <div className="mt-6 relative">
                    <div className="top-1 start-3 bottom-0 w-5 h-5 absolute translate-y-1/2">
                      <PasswordIcon />
                    </div>
                    <input name="confirm-password" type={passwordVisibility.confirmpassword ? 'text' : 'password'} autoComplete="confirm-password" placeholder="confirm password" className="block w-full text-dark-gray rounded-full p-3 px-11 border border-bd-light focus-visible:outline-none placeholder:text-dark-gray focus:border-bd-dark-gray transition bg-transparent" />
                    <button type="button" onClick={() => togglePasswordVisibility('confirmpassword')} className=" top-0 end-3 bottom-0 absolute focus-visible:outline-none" >
                      <div aria-label={passwordVisibility.confirmpassword ? "Hide password" : "Show password"}>{passwordVisibility.confirmpassword ? <EyeIcon /> : <EyeSlashIcon />}</div>
                    </button>
                  </div>
                  <div className="mt-6 text-center">
                    <button className=" px-6 py-3 btn-gradient uppercase text-sm font-medium rounded-full">continue</button>
                  </div>
                </form>
                <div className="text-center login-dotline-title relative sm:my-5 my-3">
                  <p className="text-dark-gray "><span className="font-bold">Login</span> with Others</p>
                </div>
                <div className=" text-center">
                  <button className=" px-6 py-3 btn-gray text-sm rounded-full  flex items-center justify-center w-full"><img src={googleColorIcon} alt="email-icon" className="me-2 w-6 h-6" /> <p className="text-dark-gray">Login with google</p></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};

export default ChangePassword;
