import React, { useRef, useState, useEffect } from 'react';
import logo from '../../assets/images/light/logo.png';
import Darklogo from '../../assets/images/dark/logo.png';
import { Link } from 'react-router-dom';
import ThemeToggle from '../../components/CommanLayout/ThemeToggle';
import ProfileDropDown from './components/ProfileDropDown';


const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const menuRef = useRef(null);
  const checkboxRef = useRef(null);
  const [scrolltopdata, setscrolltopdata] = useState('');

  const toggleMenu = () => {
    setActiveMenu((prev) => !prev);
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  };

  const menuItems = [
    { id: 1, title: "Home", url: "/" },
    { id: 2, title: "Analysis", url: "/stock-catagory" },
    { id: 2, title: "Watchlist", url: "/watchlist/1" },
  ];

  // Close menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (checkboxRef.current) {
          checkboxRef.current.checked = false;
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
      window.addEventListener('scroll', () => {
          if (window.scrollY < 15) {
              setscrolltopdata('');
          } else {
              setscrolltopdata('scrolled');
          }
      });
  }, [])
//   useEffect(() => {
//     const handleScroll = () => {
//         if (window.scrollY < 15) {
//           setscrolltopdata('');
//         } else {
//           setscrolltopdata('scrolled');
//         }
//     };

//     if (window.innerWidth >= 991) {
//         window.addEventListener('scroll', handleScroll);
//     }

//     // Cleanup the event listener
//     return () => {
//         window.removeEventListener('scroll', handleScroll);
//     };
// }, []);

  return (
    <>
     <header className={`header-wrapper ${scrolltopdata} sticky top-0  z-1`}>
        <nav className=" w-full z-20 top-0 left-0 ">
          <div className="container mx-auto px-4">
            <div className="navigation  flex  justify-between items-center relative mx-auto py-2 px-2 rounded-[5px]">
              <div className="logo me-14 lg:me-4 xl:block lg:block flex align-middle xl:justify-between lg:justify-between  lg:w-auto w-full items-center sm-moblie:me-[25px] md:me-12 md:gap-3  sm-moblie:gap-2">
                <Link to="/">
                  <img src={logo} alt="logo" className=" h-8 object-contain light-img-hs sm-moblie:h-6" />
                  <img src={Darklogo} alt="logo" className=" h-8 object-contain dark-img-hs sm-moblie:h-6" />
                </Link>
                <div className='xl:hidden lg:hidden block md:ml-auto sm-moblie:ml-auto'>
                  <ThemeToggle />
                </div>
                <div className='xl:hidden lg:hidden block'>
                  <ProfileDropDown />
                </div>
              </div>
              <input ref={checkboxRef} type="checkbox" id="check" />
              <span
                ref={menuRef}
                className={`menu flex [&>li]:pl-8 [&>li>a]:text-center [&>li>a]:relative [&>li>a]:transition [&>li>a]:duration-200 [&>li>a]:ease-in-out [&>li>a]:font-medium [&>li>a]:text-lg grow lg:block`}
              >
                <div className="header-wrapper lg:py-3 pb-6  w-full">
                  <div className="flex lg:flex-nowrap flex-wrap justify-between items-center">
                    <div className="toggle-menu-navbar flex items-center justify-between w-full">
                      <ul className="xl:flex lg:flex items-center text-center">
                        <label htmlFor="check" className="close-menu px-16 pt-5 text-end xl:hidden lg:hidden block text-dark-gray sm-moblie:px-4" onClick={toggleMenu}>X</label>
                        {/* <label htmlFor="check" className="close-menu px-16 pt-5 text-end xl:hidden lg:hidden block text-dark-gray">X</label> */}
                        {menuItems.map((item) => (
                          <li
                            key={item.title}
                            onClick={() => handleMenuClick(item.title)}
                            className={`${activeMenu === item.title ? "active" : ""}`}>
                            <Link className='navbar_url inline-block text-base text-dark-gray lg:px-4 lg:py-1  p-4 hover:text-purple transition duration-200 ease-in-out' to={item.url}>{item.title}</Link>
                          </li>
                        ))}
                      </ul>
                      <div className="flex xl:justify-end lg:justify-end justify-center lg:mt-0 mt-4 items-center gap-3">
                        <div className='xl:block lg:block hidden'>
                          <ThemeToggle />
                        </div>
                        <Link to="/login" className="btn-dark inline-block px-8 py-2" onClick={handleMenuClick}>Login</Link>
                        <Link to="/signup" className="btn-outline-gradient inline-block text-dark-gray rounded-lg" onClick={handleMenuClick}>
                          <p className='px-8 py-2 rounded-lg'>Register</p>
                        </Link>
                        <div className='xl:block lg:block hidden'>
                          <ProfileDropDown />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <label htmlFor="check" className="open-menu sm-moblie:right-0">
                <svg className="h-6 w-6 sm-moblie:h-5 sm-moblie:w-5" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </label>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
