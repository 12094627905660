import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import { publicRoutes } from "../../routes/allRoutes";
import MetaTag from '../../components/element/MetaTag';


const MainLayout = ({ children, meta }) => {
  let location = useLocation();
  const publicPaths = publicRoutes.map(route => route.path);
  const isPublicRoute = publicPaths.includes(location.pathname);
  return (
    <>
      {meta && (
        <MetaTag
          title={meta.title}
          description={meta.description}
          type={meta.type}
          ogDescription={meta.ogDescription}
          url={meta.url}
          ogSiteName={meta.ogSiteName}
        />
      )}
      <div className="flex flex-col h-full min-h-screen">
        <Header />
        {children}
        {!isPublicRoute && <Footer />}
      </div>
    </>
  );
};

export default MainLayout;