import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { RupeesIcon } from '../../assets/images/svgfiles/Svg'
import {  Chart as ChartJS,  CategoryScale,  LinearScale,  PointElement,  LineElement,  Tooltip,  Legend,} from 'chart.js';
ChartJS.register(  CategoryScale,  LinearScale,  PointElement,  LineElement,  Tooltip,  Legend);

export default function StockCatagory() {

  const chartRef=useRef(null)
  const data = {  
    labels: ["14 Aug", "2 jan", "17 Sep", "21 Oct", "24 Nov", "18 Dec","15 Jun", "20Feb", "28Apr"],
    bezierCurve : true,
    datasets: [
      {
        label: "First dataset",
        data: [400241,100000, 150505,90505, 27585,400241,17585, 302341, 102341, 400241,200241,600241,300241, 568065,100241, 689065,400241,788566,600241,81000,912505,1200245],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        tension: 0.4, 
        borderColor: function(context) {
          const chart = context.chart;
          const {ctx, chartArea} = chart;
  
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(250, 250, 500, 0);
          gradient.addColorStop(0, '#BBDEFA');
          gradient.addColorStop(1, '#9187DE');
          return gradient;
        },
      },
      
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
   
    scales: {
      y: {
        grid: {
          drawOnChartArea: true,
          color: '#6D6D9233',
          tickColor: '#D2D6D9',
        },
        ticks: {
          color: '#D2D6D9'
        },
        border: {
          color:'#6D6D9233'
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: '#D2D6D9',
        },
        border: {
          color:'#6D6D9233'
        },
        ticks: {
          color: '#D2D6D9',
        }
      }
    }
  
  };
  return (
    <>
    <div className='index-section flex-1 lg:py-12 sm:py-16 py-12 sm-moblie:py-7'>
      <div className='container mx-auto px-4'>
        <div className=' card bg-blur border-bd-light rounded-2xl'>
          <div className=' card-header p-3 pb-0'>
            <div className='flex items-center justify-between flex-wrap '>
              <div className='flex items-start m-2 sm-moblie:flex-wrap sm-moblie:mx-0'>
                <h4 className=' mb-0 text-6xl font-semibold me-8 sm-moblie:me-0 sm-moblie:text-5xl'>Tata Motors Ltd</h4>
                <div>
                  <div>
                    <div className='flex items-center '>
                      <h4 className='mb-0 font-semibold text-4xl flex items-center'>
                        <RupeesIcon/>
                        <span className=" ms-2">1,708</span>
                      </h4>
                      <span className=' text-success mx-2 text-xs font-bold'>+9.27%</span> 
                      <div className=' light-green-badge flex items-center'> 
                        <svg className=' me-1' width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49899 0C5.21361 0 3.9571 0.380242 2.88835 1.09264C1.81959 1.80504 0.986602 2.81761 0.494709 4.00229C0.00281597 5.18696 -0.125886 6.49055 0.124879 7.7482C0.375644 9.00585 0.994613 10.1611 1.90351 11.0678C2.81241 11.9745 3.97042 12.592 5.2311 12.8421C6.49178 13.0923 7.79851 12.9639 8.98604 12.4732C10.1736 11.9825 11.1886 11.1515 11.9027 10.0853C12.6168 9.01914 12.998 7.76565 12.998 6.48336C12.9974 4.76404 12.3125 3.11531 11.0938 1.89956C9.87516 0.683823 8.22245 0.00057282 6.49899 0ZM9.22423 5.89121C9.16977 6.02216 9.07787 6.13421 8.95998 6.21338C8.84209 6.29256 8.70344 6.33534 8.56134 6.3364H7.94177V9.88496C7.94234 9.9489 7.93021 10.0123 7.90608 10.0715C7.88195 10.1308 7.84629 10.1847 7.80118 10.2301C7.75606 10.2755 7.70237 10.3115 7.64321 10.3361C7.58406 10.3607 7.5206 10.3734 7.45651 10.3734H5.54147C5.47738 10.3734 5.41392 10.3607 5.35477 10.3361C5.29561 10.3115 5.24192 10.2755 5.1968 10.2301C5.15168 10.1847 5.11603 10.1308 5.0919 10.0715C5.06777 10.0123 5.05564 9.9489 5.05621 9.88496V6.3364H4.43664C4.29454 6.33534 4.15589 6.29256 4.038 6.21338C3.92011 6.13421 3.82821 6.02216 3.77375 5.89121C3.70988 5.74758 3.68939 5.58848 3.71478 5.43341C3.74018 5.27833 3.81036 5.13401 3.91673 5.01812L5.9834 2.81378C6.04989 2.7441 6.12988 2.68863 6.21851 2.65073C6.30714 2.61282 6.40256 2.59328 6.49899 2.59328C6.59542 2.59328 6.69084 2.61282 6.77947 2.65073C6.8681 2.68863 6.94809 2.7441 7.01458 2.81378L9.08125 5.01812C9.18762 5.13401 9.2578 5.27833 9.2832 5.43341C9.30859 5.58848 9.2881 5.74758 9.22423 5.89121Z" fill="#1BAF4B"/></svg> 
                        <span>2.78%</span>
                      </div>
                    </div>
                  </div>
                  <p className=' text-xs text-dark-gray'>13 Aug 9:30 Am</p>
                </div>
              </div>
              <div className='m-2 sm-moblie:mx-0'>
                <button className=' btn bg-dark-light-purple p-3 px-4 rounded-xl font-medium text-white  '>+ Add to watchlist</button>
              </div>
            </div>
            <div className='flex items-start justify-between flex-wrap gap-3'>
              <div className='filter-tab '>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="oneM" className="hidden"/>
                  <label className=" mb-0 " htmlFor="oneM">1m</label>
                </div>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="sixM" className="hidden"/>
                  <label className=" mb-0 " htmlFor="sixM">6m</label>
                </div>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="oneYear" className="hidden"/>
                  <label className=" mb-0 " htmlFor="oneYear">1Yr</label>
                </div>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="threeYear" className="hidden"/>
                  <label className=" mb-0 " htmlFor="threeYear">3Yr</label>
                </div>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="fiveYear" className="hidden"/>
                  <label className="mb-0" htmlFor="fiveYear">5Yr</label>
                </div>
                <div className='radio-filter'>
                  <input type="radio" name="time-filter" id="max" className="hidden" defaultChecked/>
                  <label className="mb-0" htmlFor="max">Max</label>
                </div>
              </div>
              <div className=' flex items-center gap-3'>
                <div className='filter-tab '>
                  <div className='radio-filter'>
                      <input type="radio" name="filter" id="Price" className="hidden" defaultChecked/>
                      <label className=" mb-0 " htmlFor="Price">Price</label>
                  </div>
                  <div className='radio-filter'>
                      <input type="radio" name="filter" id="PE-Ratio" className="hidden"/>
                      <label className=" mb-0 " htmlFor="PE-Ratio">PE Ratio</label>
                  </div>
                  <div className='radio-filter'>
                      <input type="radio" name="filter" id="more" className="hidden" />
                      <label className=" mb-0 " htmlFor="more">More</label>
                  </div>
                </div>
                <button className=' bg-blur p-2 px-3 rounded-xl  text-sm font-medium flex items-center border border-bd-light'>
                  <svg className='me-2' width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.32753 15.3415C8.21217 15.3415 8.97396 14.8003 9.30822 14.0279H5.34684C5.6811 14.8003 6.44292 15.3415 7.32753 15.3415ZM11.7951 7.63136V6.67213C11.7951 4.63406 10.4574 2.90664 8.62457 2.34244V1.7099C8.62457 0.985614 8.04272 0.396362 7.32753 0.396362C6.61234 0.396362 6.03049 0.985614 6.03049 1.7099V2.34244C4.19763 2.90664 2.85995 4.63403 2.85995 6.67213V7.63136C2.85995 9.42162 2.18613 11.1192 0.962616 12.4113C0.903951 12.4733 0.864555 12.5513 0.849324 12.6357C0.834094 12.7202 0.843701 12.8073 0.876952 12.8862C0.910202 12.9651 0.96563 13.0324 1.03634 13.0797C1.10706 13.127 1.18994 13.1523 1.27468 13.1522H13.3804C13.4651 13.1522 13.548 13.127 13.6187 13.0797C13.6894 13.0324 13.7448 12.9651 13.7781 12.8862C13.8113 12.8072 13.8209 12.7202 13.8057 12.6357C13.7905 12.5513 13.7511 12.4733 13.6924 12.4113C12.4689 11.1192 11.7951 9.42159 11.7951 7.63136ZM7.75988 2.16899C7.61758 2.1551 7.47338 2.14774 7.32753 2.14774C7.18169 2.14774 7.03748 2.1551 6.89518 2.16899V1.7099C6.89518 1.46847 7.08913 1.27205 7.32753 1.27205C7.56593 1.27205 7.75988 1.46847 7.75988 1.7099V2.16899ZM12.948 6.67213C12.948 6.91394 13.1416 7.10998 13.3804 7.10998C13.6191 7.10998 13.8127 6.91394 13.8127 6.67213C13.8127 4.91784 13.1381 3.26854 11.9133 2.02806C11.7444 1.8571 11.4707 1.85707 11.3018 2.02806C11.133 2.19906 11.133 2.47627 11.3018 2.64726C12.3634 3.72235 12.948 5.15173 12.948 6.67213ZM1.27468 7.10998C1.51345 7.10998 1.70703 6.91394 1.70703 6.67213C1.70703 5.15176 2.29168 3.72238 3.35323 2.64729C3.52208 2.4763 3.52208 2.19909 3.35323 2.02809C3.18441 1.8571 2.91065 1.8571 2.74181 2.02809C1.51691 3.26856 0.842337 4.91784 0.842337 6.67213C0.842337 6.91394 1.03591 7.10998 1.27468 7.10998Z" fill="#FF8B8B"/>
                  </svg>
                  <p className=' text-danger'>Alerts</p>
                </button>
              </div>
            </div>
          </div>
          <div className='card-body p-4 flex-auto'>
            <div className='rounded-2xl border border-bd-light p-4 mb-6 bg-blur'>
            <div style={{ position: "relative", width: "100%", minHeight: "400px" }}>
              <Line data={data} options={options} ref={chartRef}/>
              </div>
              <div className=" flex items-center justify-center pt-8 gap-3 flex-wrap">
                <div className="inline-flex items-center ">
                  <label className="flex items-center cursor-pointer relative" htmlFor="Price-On-NSE">
                    <input type="checkbox" className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-purple checked:border-purple" id="Price-On-NSE" name="chart-filter" />
                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                        stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="cursor-pointer ml-2 text-dark-gray text-xs font-normal" htmlFor="Price-On-NSE">
                    Price On NSE
                  </label>
                </div>
                <div className="inline-flex items-center ml-4">
                  <label className="flex items-center cursor-pointer relative" htmlFor="50-DMA">
                    <input type="checkbox" className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-purple checked:border-purple" id="50-DMA" name="chart-filter"   />
                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                        stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="cursor-pointer ml-2 text-dark-gray text-xs font-normal" htmlFor="50-DMA">
                    50 DMA
                  </label>
                </div>
                <div className="inline-flex items-center ml-4">
                  <label className="flex items-center cursor-pointer relative" htmlFor="200-DMA">
                    <input type="checkbox" className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-purple checked:border-purple" id="200-DMA" name="chart-filter"/>
                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                        stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="cursor-pointer ml-2 text-dark-gray text-xs font-normal" htmlFor="200-DMA">
                    200 DMA
                  </label>
                </div>
                <div className="inline-flex items-center ml-4">
                  <label className="flex items-center cursor-pointer relative" htmlFor="Volume">
                    <input type="checkbox" className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-purple checked:border-purple" id="Volume" name="chart-filter"/>
                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                        stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="cursor-pointer ml-2 text-dark-gray text-xs font-normal" htmlFor="Volume">
                    Volume
                  </label>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-4 grid-cols-2 gap-5 text-dark-gray font-light">
              <div className="xl:col-span-1 col-span-2">
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1">
                  <p className="mb-0 text-sm font-light">Previous Close</p>
                  <p className="">28.63</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">Open</p>
                  <p className="">28.63</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 ">
                  <p className="mb-0 text-sm font-light">Bid</p>
                  <p className="">29.83 x 3000</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">Ask</p>
                  <p className="">--</p>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-2">
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1">
                  <p className="mb-0 text-sm font-light">Day's Range</p>
                  <p className="">28.83 - 29.26</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">52 Week Range</p>
                  <p className="">23.14 - 32.13</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 ">
                  <p className="mb-0 text-sm font-light">Volume</p>
                  <p className="">2,054,817</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">Avg. Volume</p>
                  <p className="">1,679,100</p>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-2">
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1">
                  <p className="mb-0 text-sm font-light">Market Cap (intraday)</p>
                  <p className="">93.788B</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">Beta (5Y Monthly)</p>
                  <p className="">1.04</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 ">
                  <p className="mb-0 text-sm font-light">PE Ratio (TTM)</p>
                  <p className="">3.38</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">EPS (TTM)</p>
                  <p className="">8.65</p>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-2">
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1">
                  <p className="mb-0 text-sm font-light">Earnings Date</p>
                  <p className="">93.788B</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">Dividend & Yield</p>
                  <p className="">1.04</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 ">
                  <p className="mb-0 text-sm font-light">Ex-Dividend Date</p>
                  <p className="">3.38</p>
                </div>
                <div className=" flex items-center justify-between p-2 px-3 rounded-xl mb-1 bg-[#B6B6B60D] dark:bg-blur">
                  <p className="mb-0 text-sm font-light">1y Target Est</p>
                  <p className="">8.65</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>
  )
}


