import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EditIcon } from '../../assets/images/svgfiles/Svg'
import 'swiper/css';
import { Link, useParams } from "react-router-dom";
import { MomentumPerformanceAnalysis } from "../../utiles/AnalysisData";

export default function Watchlist() {
  const { id } = useParams();
  const item = MomentumPerformanceAnalysis.find((entry) => entry.id === parseInt(id)); 
  const [swiper, setSwiper] = useState(null);
  const repetitions = new Array(10).fill('');
  return (
    <>
      <div className='watchlist-section flex-1 lg:py-12 sm:py-16 py-12 sm-moblie:py-7'>
        <div className='container mx-auto px-4'>
          <div className=" flex items-center mb-3">
            <h4 className=" text-6xl font-semibold me-3 sm-moblie:text-5xl">{item.title}</h4>
            <Link className="p-2 btn-blur rounded-lg "><EditIcon /></Link>
          </div>
          <div className=" card bg-blur p-4 rounded-xl mb-4">
            <div className=" border rounded-xl bg-blur border-bd-light overflow-auto">
              <table className="table-auto cs-table w-full">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>High</th>
                    <th>Low</th>
                    <th>Close</th>
                    <th>Adj Close</th>
                    <th>Volume</th>
                    <th>P/E</th>
                    <th>Mar Cap Rs.Cr.</th>
                    <th>Div Yld %</th>
                    <th>NP Qtr Rs.Cr.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td><Link to='/stock-data'>Maruti Suzuki</Link></td>
                    <td>344.48</td>
                    <td>335.11</td>
                    <td>343.36</td>
                    <td>343.36</td>
                    <td>7,943,700</td>
                    <td>343.36</td>
                    <td>343.36</td>
                    <td>335.11</td>
                    <td>3759.70</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Tata Motors</td>
                    <td>344.48</td>
                    <td>335.11</td>
                    <td>343.36</td>
                    <td>343.36</td>
                    <td>7,943,700</td>
                    <td>343.36</td>
                    <td>343.36</td>
                    <td>335.11</td>
                    <td>3759.70</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex items-center justify-end flex-wrap">
            <div className="m-2 sm-moblie:w-full"><Link className=" bg-dark-light-purple p-3 px-4 rounded-xl font-medium text-white  sm-moblie:w-full" to='/create-watchlist'>+ Create Watchlist</Link></div>
            <div className="m-2 sm-moblie:w-full"><button className=" btn-outline-gradient inline-block text-dark-gray rounded-xl font-medium sm-moblie:w-full"><p className=" bg-white p-3 px-4 rounded-xl">+ Companies</p></button></div>
          </div>
          <div className=" flex items-center justify-between">
            <h4 className=" text-5xl font-semibold me-3 bd-b-purple relative py-3 mb-3">Related Tickers</h4>
            <div className="flex items-center gap-4">
              <button className="btn-swiper" onClick={() => swiper.slidePrev()}>
                <span>
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.80482e-07 6.87825C-7.88778e-05 6.95486 0.0149801 7.03074 0.0443113 7.10151C0.0736425 7.17229 0.116667 7.23657 0.170915 7.29067L6.00422 13.124C6.23216 13.3519 6.60126 13.3519 6.82905 13.124C7.05684 12.896 7.05698 12.5269 6.82905 12.2991L1.40816 6.87825L6.82905 1.45736C7.05698 1.22943 7.05698 0.860323 6.82905 0.632532C6.60111 0.404742 6.23201 0.404596 6.00422 0.632532L0.170915 6.46584C0.116667 6.51993 0.0736425 6.58422 0.0443113 6.65499C0.0149801 6.72577 -7.88711e-05 6.80164 2.80482e-07 6.87825Z" fill="white" fillOpacity="0.5" />
                  </svg>
                </span>
              </button>
              <button className=" btn-swiper" onClick={() => swiper.slideNext()}>
                <span>
                  <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 6.49983C7.00008 6.57645 6.98502 6.65232 6.95569 6.72309C6.92636 6.79387 6.88333 6.85815 6.82908 6.91225L0.995781 12.7456C0.767845 12.9735 0.398743 12.9735 0.170952 12.7456C-0.0568383 12.5176 -0.0569841 12.1485 0.170952 11.9207L5.59184 6.49983L0.170952 1.07895C-0.0569846 0.851008 -0.0569846 0.481905 0.170952 0.254114C0.398888 0.0263243 0.76799 0.0261783 0.995781 0.254114L6.82908 6.08742C6.88333 6.14152 6.92636 6.2058 6.95569 6.27657C6.98502 6.34735 7.00008 6.42322 7 6.49983Z" fill="white" fillOpacity="0.5" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="py-2">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              onSwiper={(s) => { setSwiper(s) }}
              className="TickerSwiper">
              {repetitions.map((_, index) => (
                <SwiperSlide key={index}>
                  <div className=" bg-blur border-bd-light border rounded-xl p-4 pe-12">
                    <div className=" ">
                      <h5 className=" text-gradient mb-2">PANW</h5>
                      <p className=" mb-2">Palo Alto Networks, Inc.</p>
                      <div className=" flex items-center">
                        <h6 className=" text-xl font-semibold mb-0">343.36</h6>
                        <span className=" text-success mx-2 text-base font-normal">+9.27%</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

    </>
  )
}


