import React from 'react'
// import { EmailIcon1, SearchIcon1 } from '../../assets/images/svgfiles/SearchIcon'
import { SearchIcon } from '../../assets/images/svgfiles/Svg'

export default function Home() {
  return (
    <>
    <div className='banner-section flex-1 bg-overley sm-moblie:pt-[20px] sm-moblie:pb-[50px] sm-moblie:px-4'>
      <div className='container mx-auto '>
        <h1 className='font-bold font-Montserrat lg:text-10xl md:text-6xl text-center text-txt-lg-white sm-moblie:text-xl'>Easily invest in stocks and <br /> crypto in <span className="bg-gradient-to-b from-[#9187DE] to-[#BBDEFA] bg-clip-text text-transparent">One Platform</span>
        </h1>
        <p className='text-dark-gray mb-12 mt-4 text-xl font-normal text-center sm-moblie:mb-[25px] sm-moblie:text-sm'>Stock analysis and screening tool for investors in India.</p>
        <div className='search-function max-w-[600px] mx-auto'>
          <div className="flex rounded-full p-1 lg:p-2 md:p-2 sm:p-1 mb-6 main-search sm-moblie:mb-[20px]">
            <button type="submit" className="relative p-2 rounded-full"><SearchIcon className='sm-moblie:size-4'/></button>
            <input type="text" className="w-full flex bg-transparent pl-2 text-dark-gray outline-0" placeholder="Search for a company"/>
          </div>
        </div>
        <div className='max-w-[800px] mx-auto flex items-start sm-moblie:flex-wrap md:max-w-[700px]'>
          <div className=' p-2'>
              <p className='text-th-black mb-0 whitespace-pre'>Or analyse:</p>
          </div>  
          <div className='flex items-center gap-3 flex-wrap'>
            <button to="" className="badge-outline-blur rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              Top Gainers
            </button>
            <button to="" className="badge-outline-blur rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              Top Losers
            </button>
            <button to="" className="badge-outline-blur rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              52 Week High
            </button>
            <button to="" className="badge-outline-blur rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              52 Week Low
            </button>
            <button to="" className="badge-outline-blur rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              52 Week Low
            </button>
            <button to="" className="border border-bd-dark-gray rounded-full px-3 py-2 text-dark-gray sm-moblie:text-sm">
              View more +
            </button>
          </div>
        </div>
      </div>
    </div>
    
    </>
  )
}
