import React from 'react';
import { CorporateActionsGovernance, DividendIncomeBasedAnalysis, EventDrivenAnalysis, FinancialHealthGrowthIndicators, InvestorSentimentInsiderActivity, MomentumPerformanceAnalysis, SectorThematicBasedAnalysis, SpecialSituations, TechnicalAnalysisBased, TradingVolumeActivity, ValuationBasedAnalysis, VolatilityRiskBasedAnalysis } from '../../utiles/AnalysisData';
import CardGrid from './component/CardGrid';

export default function StockCatagory() {


  return (
    <>
      <div className='stock-catagory-section flex-1 lg:py-12 sm:py-16 md:py-10 py-12 sm-moblie:py-7'>
        <div className='container mx-auto px-4'>
          <CardGrid
            title='Momentum & Performance-Based Analysis'
            data={MomentumPerformanceAnalysis}
          />
          <CardGrid
            title='Trading Volume & Activity'
            data={TradingVolumeActivity}
          />
          <CardGrid
            title='Investor Sentiment & Insider Activity'
            data={InvestorSentimentInsiderActivity}
          />
          <CardGrid
            title='Valuation-Based Analysis'
            data={ValuationBasedAnalysis}
          />
          <CardGrid
            title='Dividend & Income-Based Analysis'
            data={DividendIncomeBasedAnalysis}
          />
          <CardGrid
            title='Financial Health & Growth Indicators'
            data={FinancialHealthGrowthIndicators}
          />
          <CardGrid
            title='Volatility & Risk-Based Analysis'
            data={VolatilityRiskBasedAnalysis}
          />
          <CardGrid
            title='Sector & Thematic-Based Analysis'
            data={SectorThematicBasedAnalysis}
          />
          <CardGrid
            title='Corporate Actions & Governance'
            data={CorporateActionsGovernance}
          />
          <CardGrid
            title='Technical Analysis-Based'
            data={TechnicalAnalysisBased}
          />
          <CardGrid
            title='Special Situations'
            data={SpecialSituations}
          />
          <CardGrid
            title='Event-Driven Analysis'
            data={EventDrivenAnalysis}
          />
        </div>
      </div>
    </>
  );
}
